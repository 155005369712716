



































import { Component, Mixins, Ref } from "vue-property-decorator";
import SearchBase from "@/components/admin_common/SearchBase.vue";
import { Inquiry } from "@/model/inquiry";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import InquiryList from "@/components/inquiry/InquiryList.vue";
import {
  INQUIRY_STATUS,
  ANSWER_STATUS,
  ANSWER_COMPLETED,
  INQUIRY_PUBLISHED,
  INQUIRY_VERSION,
  HOPE_CONTACT_DIV,
} from "@/consts/inquiry";
import SelectFilter from "#/components/selectFilter/SelectFilter.vue";
import {
  ColumnKey,
  FilterClause,
  IsFilterMatch,
} from "#/components/selectFilter/filter";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { SELECT_FILTER } from "#/const";
import { AdminUser } from "@/model/adminUser";
import FireStoreMixin from "#/mixins/firestoreMixin";
import { COLLECTION_INQUIRY_FILTER_OBJ } from "@/consts/envFireStore";
import UserMixin from "@/mixins/userMixin";

export interface HeaderColumn {
  text: string;
  value: string;
  sortable: boolean;
  align?: string;
  width?: string;
  selected?: boolean;
  filterCondition?: number;
  filterType?: number;
  filter?: (val: object) => boolean;
  columnKey?: ColumnKey;
}

/** 表示用Inquiry */
interface DispInquiry extends Inquiry {
  ticket_no: string;
  last_answered_datetime: string;
  co_code: string;
  kst_no: string;
  corresponding_admin_names: string;
  use_receipt_type: number;
  inquiry_date: string;
  inquiry_time: string;
  all_answer_contents: string;
  answer_statuses: number;
  need_mark_hope_contact_date_time: number;
  inquiry_staff_name: string;
  inquiry_staff_furigana: string;
  co_name: string;
  office_name: string;
  normalized_inquiry_content: string;
}

interface InquiryFilterClauses {
  clauses: FilterClause[];
  sortBy: unknown[];
  sortDesc: unknown[];
}

@Component({
  components: { SearchBase, InquiryList, AppAuthButton, SelectFilter },
})
export default class InProgress extends Mixins(
  AxiosMixin,
  UtilMixin,
  FireStoreMixin,
  UserMixin
) {
  /** フィルター */
  @Ref("selectFilter") private readonly selectFilter!: SelectFilter;

  /** 並び項目 */
  private sortBy: unknown[] = [];

  /** 並び順 */
  private sortDesc: unknown[] = [];

  /** 一覧表示用問い合わせ情報 */
  private inquirys = [] as DispInquiry[];

  /** 管理者一覧 */
  private adminNames: string[] = [];

  /** 問い合わせタグマスタ */
  private inquiryTagNames: string[] = [];

  /** ヘッダー情報 */
  private get headers(): HeaderColumn[] {
    return [
      {
        text: "",
        value: "inquiry_tag_name",
        sortable: false,
        align: "center",
        selected: false,
        width: "100px",
        filterType: SELECT_FILTER.FILTER_TYPE.LIST,
        filter: (val: object) => this.columnFilter("inquiry_tag_name", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.LIST,
          key: "inquiry_tag_name",
          text: "問い合わせタグ",
          selectable: this.inquiryTagNames,
        },
      },
      {
        text: "詳細",
        value: "actions",
        sortable: false,
        align: "center",
        selected: false,
        width: "50px",
      },
      {
        text: "ステータス",
        sortable: false,
        value: "status",
        filterType: SELECT_FILTER.FILTER_TYPE.EQUAL_LIST,
        filter: (val: object) => this.columnFilter("status", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EQUAL_LIST,
          key: "status",
          text: "ステータス",
          selectable: ["未対応", "進行中", "連絡とれず"],
        },
        width: "150px",
      },
      {
        text: "バージョン",
        sortable: false,
        value: "version",
        filterType: SELECT_FILTER.FILTER_TYPE.LIST,
        filter: (val: object) => this.columnFilter("version", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.LIST,
          key: "version",
          text: "バージョン",
          selectable: ["v2", "v1"],
        },
        width: "100px",
      },
      {
        text: "回答ステータス(フィルター用ダミー)",
        align: " d-none",
        sortable: false,
        value: "answer_statuses",
        filterType: SELECT_FILTER.FILTER_TYPE.LIST,
        filter: (val: object) => this.columnFilter("answer_statuses", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.LIST,
          key: "answer_statuses",
          text: "回答ステータス",
          selectable: [
            "アサイン",
            "対応中",
            "連絡待ち（外）",
            "連絡待ち（内）",
          ],
        },
      },
      {
        text: "問い合わせ公開設定(フィルター用ダミー)",
        align: " d-none",
        sortable: false,
        value: "is_published",
        filterType: SELECT_FILTER.FILTER_TYPE.LIST,
        filter: (val: object) => this.columnFilter("is_published", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.LIST,
          key: "is_published",
          text: "問い合わせ公開設定",
          selectable: ["非公開", "公開"],
        },
      },
      {
        text: "回答公開設定(フィルター用ダミー)",
        align: " d-none",
        sortable: false,
        value: "answer_is_published",
        filterType: SELECT_FILTER.FILTER_TYPE.LIST,
        filter: (val: object) => this.columnFilter("answer_is_published", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.LIST,
          key: "answer_is_published",
          text: "回答公開設定",
          selectable: ["非公開", "公開"],
        },
      },
      {
        text: "問い合わせ内容(フィルター用ダミー)",
        align: " d-none",
        sortable: false,
        value: "normalized_inquiry_content",
        filterType: SELECT_FILTER.FILTER_TYPE.EDIT_PARTIAL,
        filter: (val: object) =>
          this.columnFilter("normalized_inquiry_content", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT_PARTIAL,
          key: "normalized_inquiry_content",
          text: "問い合わせ内容",
          selectable: [],
        },
      },
      {
        text: "希望連絡方法(チケット番号)",
        align: "center",
        sortable: false,
        value: "hope",
        width: "190px",
      },
      {
        text: "希望連絡方法(フィルター用ダミー)",
        align: " d-none",
        sortable: false,
        value: "hope_contact_div",
        filterType: SELECT_FILTER.FILTER_TYPE.LIST,
        filter: (val: object) => this.columnFilter("hope_contact_div", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.LIST,
          key: "hope_contact_div",
          text: "希望連絡方法",
          selectable: HOPE_CONTACT_DIV.map((choice) => choice.text),
        },
      },
      {
        text: "チケット番号(フィルター用ダミー)",
        align: " d-none",
        sortable: false,
        value: "ticket_no",
        filterType: SELECT_FILTER.FILTER_TYPE.EDIT,
        filter: (val: object) => this.columnFilter("ticket_no", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "ticket_no",
          text: "チケット番号",
          selectable: [],
        },
      },
      {
        text: "企業コード(フィルター用ダミー)",
        align: " d-none",
        sortable: false,
        value: "co_code",
        filterType: SELECT_FILTER.FILTER_TYPE.EDIT,
        filter: (val: object) => this.columnFilter("co_code", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "co_code",
          text: "企業コード",
          selectable: [],
        },
      },
      {
        text: "KST番号(フィルター用ダミー)",
        align: " d-none",
        sortable: false,
        value: "kst_no",
        filterType: SELECT_FILTER.FILTER_TYPE.EDIT,
        filter: (val: object) => this.columnFilter("kst_no", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "kst_no",
          text: "KST番号",
          selectable: [],
        },
      },
      {
        text: "回答内容(フィルター用ダミー)",
        align: " d-none",
        sortable: false,
        value: "all_answer_contents",
        filterType: SELECT_FILTER.FILTER_TYPE.EDIT_PARTIAL,
        filter: (val: object) => this.columnFilter("all_answer_contents", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT_PARTIAL,
          key: "all_answer_contents",
          text: "回答内容",
          selectable: [],
        },
      },
      {
        text: "事業所名",
        align: "start",
        sortable: true,
        value: "office_name",
        filterType: SELECT_FILTER.FILTER_TYPE.EDIT,
        filter: (val: object) => this.columnFilter("office_name", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "office_name",
          text: "事業所名",
          selectable: [],
        },
        width: "180px",
      },
      {
        text: "対応者",
        align: "start",
        sortable: false,
        value: "corresponding_admin_names",
        filterType: SELECT_FILTER.FILTER_TYPE.EDIT,
        filter: (val: object) =>
          this.columnFilter("corresponding_admin_names", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "corresponding_admin_names",
          text: "対応者",
          selectable: [],
        },
        width: "150px",
      },
      {
        text: "問い合わせ日",
        align: "start",
        sortable: true,
        value: "inquiry_date",
        width: "150px",
        filterType: SELECT_FILTER.FILTER_TYPE.DATE,
        filter: (val: object) => this.columnFilter("inquiry_date", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.DATE,
          key: "inquiry_date",
          text: "問合せ日",
          selectable: [],
        },
      },
      {
        text: "問い合わせ時刻",
        align: "start",
        sortable: true,
        value: "inquiry_time",
        width: "150px",
        filterType: SELECT_FILTER.FILTER_TYPE.EDIT,
        filter: (val: object) => this.columnFilter("inquiry_time", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "inquiry_time",
          text: "問合せ時刻",
          selectable: [],
        },
      },
      {
        text: "希望連絡日時",
        align: "start",
        sortable: true,
        value: "hope_contact_datetime",
        width: "150px",
      },
      {
        text: "最終回答日時",
        align: "start",
        sortable: true,
        value: "last_answered_datetime",
        width: "150px",
        filterType: SELECT_FILTER.FILTER_TYPE.DATE,
        filter: (val: object) =>
          this.columnFilter("last_answered_datetime", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.DATE,
          key: "last_answered_datetime",
          text: "最終回答日時",
          selectable: [],
        },
      },
      {
        text: "企業名",
        align: "start",
        sortable: true,
        value: "co_name",
        filterType: SELECT_FILTER.FILTER_TYPE.EDIT,
        filter: (val: object) => this.columnFilter("co_name", val),
        columnKey: {
          type: SELECT_FILTER.FILTER_TYPE.EDIT,
          key: "co_name",
          text: "企業名",
          selectable: [],
        },
        width: "150px",
      },
    ];
  }

  private canSaveStore = false;

  async created() {
    this.collection = COLLECTION_INQUIRY_FILTER_OBJ;
    this.documentId = String(this.User.id);
    const data = await this.documentGet();
    if (data) {
      const clauses = data as InquiryFilterClauses;
      this.selectFilter.clearAll();
      clauses.clauses.forEach((data) => {
        this.selectFilter.filterClauses.push(data);
      });
      this.sortBy = clauses.sortBy;
      this.sortDesc = clauses.sortDesc;
    }
    this.canSaveStore = true;
  }

  // APIを呼んで完了状態以外の問い合わせ情報を全取得
  private async fetchInquiries() {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiries/inprogress/get",
      {},
      (res) => {
        this.inquirys = res.data.inquirys;
        this.adminNames = res.data.admin_users.map((adminUser: AdminUser) => {
          return `${adminUser.last_name} ${adminUser.first_name}`;
        });
        this.inquiryTagNames = res.data.inquiry_tag_names;
      }
    );
  }

  /** 問い合わせ詳細画面へ遷移（別タブ） */
  private gotoEditPage(id: number) {
    window.open("/inquiry/show/" + id, "_blank");
  }

  /**
   * SelectFilterの選択肢
   * @return ColumnKey
   */
  private get columnKeys(): ColumnKey[] {
    const keys = this.headers
      .filter((headerColumn: HeaderColumn) => headerColumn.filter != null)
      .map((headerColumn: HeaderColumn) => headerColumn.columnKey);

    if (keys.length == 0) {
      return [] as ColumnKey[];
    }

    return keys as ColumnKey[];
  }

  /** カラムフィルター */
  private columnFilter(key: string, val: unknown) {
    switch (key) {
      case "status":
        val = this.idToText(val as number, INQUIRY_STATUS);
        break;
      case "version":
        val = this.idToText(val as number, INQUIRY_VERSION);
        break;
      case "answer_statuses":
        val = (val as []).flatMap((status: number) => {
          return status < ANSWER_COMPLETED
            ? this.idToText(status, ANSWER_STATUS)
            : [];
        });
        val = (val as []).join(" ");
        break;
      case "answer_is_published":
      case "is_published":
        val = this.idToText(val as number, INQUIRY_PUBLISHED);
        break;
      case "hope_contact_div":
        val = this.idToText(val as number, HOPE_CONTACT_DIV);
        break;
      default:
        break;
    }
    const bret = IsFilterMatch(
      this.selectFilter.filterClauses,
      key,
      val as string | number
    );
    return bret;
  }

  //並び順序変更
  private updateSortBy(value: []) {
    this.sortBy = value;
    this.onChangeCondition();
  }

  //並び項目変更
  private updateSortDesc(value: []) {
    this.sortDesc = value;
    this.onChangeCondition();
  }

  /**
   * フィルター条件の変更確定時
   * 単体削除、全体削除、入力後のブラー
   */
  private onChangeCondition() {
    if (this.canSaveStore) {
      const clauses: InquiryFilterClauses = {
        clauses: this.selectFilter.filterClauses,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      };
      this.documentSave(clauses);
    }
  }
}
