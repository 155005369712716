







































import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import {
  OfficestaffContact,
  DEFAULT_OFFICESTAFF_CONTACT,
} from "@/model/inquiry";
import AxiosMixin from "@/mixins/axiosMixin";
import UserMixin from "@/mixins/userMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import UtilMixin from "@/mixins/utilMixin";

@Component({
  components: {
    AppAuthButton,
  },
})
export default class OfficestaffContactInfo extends Mixins(
  UtilMixin,
  AxiosMixin,
  UserMixin
) {
  private isEditContactHistory = false;

  /** コンタクト履歴バー表示・非表示 */
  private panel = 0;

  /** 編集可能フラグ */
  @Prop({ default: false }) editable!: boolean;

  /** 編集対象事業所職員コンタクト履歴情報 */
  @Prop({ default: () => DEFAULT_OFFICESTAFF_CONTACT })
  officestaffContact!: OfficestaffContact;

  /** 表示ユーザー名 */
  @Prop({ default: "" }) userName!: string;

  private get innerValue() {
    return { ...this.officestaffContact };
  }

  /** HTML用コンタクト履歴 */
  private get htmlContactMessage() {
    return this.innerValue.message.replace(/\r?\n/g, "<br>");
  }

  /** コンタクト履歴-編集ボタンクリック */
  private toggleEditContactHistory() {
    const date = new Date();
    const prefix = `(${this.dateToStr(date, "yyyy/MM/dd HH:mm")} ${
      this.userName
    }) =============================================\n\n`;

    this.innerValue.message = prefix + this.innerValue.message;
    this.isEditContactHistory = !this.isEditContactHistory;
  }

  /** コンタクト履歴-保存ボタンクリック */
  @Emit()
  private save() {
    this.isEditContactHistory = !this.isEditContactHistory;
    return this.innerValue;
  }

  /** 編集キャンセル時 */
  private cancel() {
    // 編集前のテキストに戻す
    this.innerValue.message = this.officestaffContact.message;
    this.isEditContactHistory = !this.isEditContactHistory;
  }
}
